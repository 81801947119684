import { Atom, Mod } from ":mods";

import { Route, useNavigate, useSearchParams } from "@solidjs/router";
import { BASE_ROUTES } from "../const";
import { actions, hooks } from "../store";
import { NotFoundWithRedirect } from "../components";
import { createEffect, createSignal } from "solid-js";
import { AuthViewProps } from "../model";
import { Auth as AuthLayout } from "../../layouts";

// TODO: put otp in it's own path while being under create account, use search params
export function Auth() {
  const $: AuthViewProps["$"] = {
    actions: {
      ...actions,
      ...Mod.Account.actions,
    },
    hooks: {
      ...hooks,
    },
  };

  function loggedIn() {
    const user_type = $.actions.getTokenType();
    console.log(user_type);
    if (!user_type) {
      $.actions.pushToast({ type: "error", message: "unknown user type, redirecting!" });
      $.actions.navigate(BASE_ROUTES.microsite);
      return;
    }
    let base: string = undefined;
    if (user_type === Mod.Account.CONST.TOKEN_TYPE.admin) {
      base = BASE_ROUTES.admin;
    } else if (user_type === Mod.Account.CONST.TOKEN_TYPE.student) {
      base = BASE_ROUTES.student;
    } else if (user_type === Mod.Account.CONST.TOKEN_TYPE.tutor) {
      base = BASE_ROUTES.tutor;
    }
    $.actions.pushToast({ type: "success", message: `logged in as ${$.actions.getTokenDetails().first_name}` });
    $.actions.navigateHref({ base });
  }

  return (
    <Atom.Route.Guarded
      base={BASE_ROUTES.auth}
      layout={() => <AuthLayout />}
      errorElement={() => (
        <NotFoundWithRedirect path={Mod.Account.CONST.ROUTES.auth.login} base={BASE_ROUTES.auth} duration={0} />
      )}
      guard={{
        title: "Museum Training",
        steps: [Mod.Account.guard.checkUserToken, Mod.Account.guard.checkUserEvents],
      }}
      events={{
        onGuardSuccess: (trigger, { state, location, routeMatch, isBaseRoute }) => {
          const user = $.actions.getTokenDetails();
          console.log("success auth route ", user);
          if (user && routeMatch(Mod.Account.CONST.ROUTES.auth.login)) {
            loggedIn();
          } else {
            if (isBaseRoute) {
              trigger.navigate(Mod.Account.CONST.ROUTES.auth.login);
            } else {
              trigger.loadRoute();
            }
          }
        },
        onGuardFailure: (trigger, { error, stepEquals, isBaseRoute, routeMatch }) => {
          console.warn("user has no token ", error);
          if (stepEquals(Mod.Account.guard.checkUserToken) && isBaseRoute) {
            if (routeMatch(Mod.Account.CONST.ROUTES.auth.login)) {
              trigger.loadRoute();
            } else {
              trigger.navigate(Mod.Account.CONST.ROUTES.auth.login);
            }
          } else {
            // console.warn("unhandled route error case in ", error);
            trigger.loadRoute();
          }
        },
        onRouteCleanup: ({ location }) => {
          // console.log("cleaning auth route , ", location, " :: ", prevLocation);
        },
        onRouteChange: ({ location }) => {
          // console.log("changing auth route , ", location, " :: ", prevLocation);
        },
      }}
    >
      {() => (
        <>
          <Mod.Account.AuthRoutes $={$} onLoggedIn={loggedIn} />
        </>
      )}
    </Atom.Route.Guarded>
  );
}
